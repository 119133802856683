import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Http } from '@angular/http';
import { CookieService } from 'ngx-cookie-service';
import { AngularFireDatabase } from 'angularfire2/database';
import { WifiUser } from 'models/WifiUser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  
  @Input() cellularNumber: string;
  @Input() emailAddress: string;
  businessName: string = "1921 by NVA";
  wifiEntry: WifiUser;

  constructor(
    private http:Http, 
    private cookieService: CookieService,
    public db: AngularFireDatabase,
    private route: ActivatedRoute) { }  

  ngOnInit() { }

  onSubmit() {
    let redirectUrl: string;

    this.cookieService.set("Charm", "Charm", 30);

    this.route.queryParams
      .subscribe(params => {
        console.log(params); // {order: "popular"}
        console.log(this.route.url);
        redirectUrl = params.base_grant_url + "?user_continue_url=" + params.user_continue_url;

        this.wifiEntry = new WifiUser(
          this.businessName, 
          this.emailAddress,
          params.base_grant_url,
          params.user_continue_url);
      });    
    
    this.db.list('/wifiUsers').push({ content: this.wifiEntry });
    this.emailAddress = '';        
    
    window.open(redirectUrl, "_self");
  }
}