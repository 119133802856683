export class WifiUser{
    businessName: string;
    emailAddress: string;
    baseGrantUrl: string;
    userContinueUrl: string;

    constructor(
        businessName: string, 
        emailAddress: string,
        baseGrantUrl: string,
        userContinueUrl: string
        ) {
        this.businessName = businessName;
        this.emailAddress = emailAddress;
        this.baseGrantUrl = baseGrantUrl;
        this.userContinueUrl = userContinueUrl;
    }
}